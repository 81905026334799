<template>
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h5 class="card-title m-0"><i class="fa fa-list"></i> List Chat</h5>
    </div>
    <div class="card-body">
      <p class="alert alert-light">
        Berikut thread / chat yang sedang aktif. Jika Anda ingin melihat thread
        yang sudah <code>closed</code> bisa dilihat di history.
      </p>
      <div
        v-if="!xhr.threads.isBusy && !xhr.threads.success"
        class="alert alert-danger"
      >
        {{ xhr.threads.record }}
      </div>
      <div v-else>
        <!--filter & length-->
        <div class="d-flex justify-content-between">
          <div class="mb-2">
            <b-form-select
              :options="[5, 10, 15, 20]"
              :value="5"
              v-model="xhr.threads.perPage"
            ></b-form-select>
          </div>
          <div>
            <b-form-input
              type="search"
              placeholder="Search"
              v-model="xhr.threads.search"
            ></b-form-input>
          </div>
        </div>

        <b-table
          id="my-table"
          striped
          hover
          show-empty
          :busy="xhr.threads.isBusy"
          :items="xhr.threads.record"
          :fields="xhr.threads.field"
          :per-page="xhr.threads.perPage"
          :filter="xhr.threads.search"
          :current-page="xhr.threads.currentPage"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-warning my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <!-- id custom -->
          <template #cell(id)="data">
            {{ data.item.id }} <br /><small>sub {{ data.item.subid }}</small>
          </template>

          <!--customer custom slot-->
          <template #cell(custname)="data">
            {{ data.item.custname }} <br />
            <small
              ><i class="fa fa-envelope-o"></i> {{ data.item.email }}</small
            >
          </template>

          <!--agent custom-->
          <template #cell(agent)="data">
            <span v-html="setAgent(data.item.agent)"></span><br />
            <small
              ><i class="fa fa-globe"></i> {{ data.item.referrer }} -
              {{ data.item.ip }}</small
            >
          </template>

          <!--last reply custom-->
          <template #cell(last_reply)="data">
            <small
              ><i class="fa fa-clock-o"></i> {{ data.item.last_time }}</small
            >
          </template>

          <!--status-->
          <template #cell(status)="data">
            <span v-html="setStatus(data.item.status)"></span>
          </template>

          <!--actions-->
          <template #cell(action)="data">
            <router-link
              class="btn btn-success btn-sm"
              :to="{ path: `/panel/chat/${data.item.subhash}` }"
              ><i class="fa fa-history"></i> Detail</router-link
            >
            &nbsp;
            <button
              v-if="!agentHasJoin(data.item.agent)"
              class="btn btn-info btn-sm"
              @click="join(data.item.subhash)"
            >
              <i class="fa fa-hand-pointer-o"></i> Join
            </button>
          </template>
        </b-table>

        <!-- pagination -->
        <b-pagination
          v-model="xhr.threads.currentPage"
          :total-rows="xhr.threads.totalRows"
          :per-page="xhr.threads.perPage"
          aria-controls="my-table"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { request, util } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import appConfig from "@/common/config/app.config.json";

export default {
  name: "ListChat",
  mixins: [request, loader, util],
  data() {
    return {
      xhr: {
        threads: {
          record: [],
          success: false,
          isBusy: true,
          field: [
            {
              key: "id",
              label: "Thread",
              sortable: true,
            },
            {
              key: "custname",
              label: "Customer",
              sortable: false,
            },
            {
              key: "agent",
              label: "Agent Name",
              sortable: false,
            },
            {
              key: "last_reply",
              label: "Last Reply",
              sortable: false,
              class: "text-center",
            },
            {
              key: "status",
              label: "Status",
              sortable: false,
              class: "text-center",
            },
            {
              key: "action",
              label: "#",
              class: "text-center",
            },
          ],
          perPage: 5,
          currentPage: 1,
          search: null,
          totalRows: 1,
        },
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * request all thread active
     * --------------------------------------------------------------
     */
    getThreads: function () {
      this.API.get(this.URL.thread.list)
        .then(({ data }) => {
          this.xhr.threads.record = data;
          this.xhr.threads.success = true;
          this.xhr.threads.totalRows = data.length;
        })
        .catch((err) => {
          this.xhr.threads.record = err;
        })
        .finally(() => {
          this.xhr.threads.isBusy = false;
        });
    },

    /**
     * --------------------------------------------------------------
     * for generate agent name
     * --------------------------------------------------------------
     */
    setAgent: function (arr_agent) {
      let agent = "";
      if (arr_agent.length !== 0) {
        let arr = [];
        for (let i = 0; i < arr_agent.length; i++) {
          let nama = arr_agent[i].nama.split(" ");
          arr.push(`${nama[0]} <small>(${arr_agent[i].dept_name})</small>`);
        }
        agent = arr.join(", ");
      }
      return agent;
    },

    /**
     * --------------------------------------------------------------
     * for generate badge status color
     * --------------------------------------------------------------
     */
    setStatus: function (status) {
      let stts = status.toLowerCase();
      let result = `<span class="badge badge-danger"><i class="fa fa-sign-out"></i> Chat Away</span>`;
      if (stts === "in chat") {
        result = `<span class="badge badge-success"><i class="fa fa-comments"></i> In Chat</span>`;
      } else if (stts === "unanswered") {
        result = `<span class="badge badge-warning"><i class="fa fa-eye-slash"></i> Unanswered</span>`;
      }
      return result;
    },

    /**
     * --------------------------------------------------------------
     * trigger ketika melakukan pencarian
     * --------------------------------------------------------------
     */
    onFiltered(filteredItems) {
      this.xhr.threads.totalRows = filteredItems.length;
      this.xhr.threads.currentPage = 1;
    },

    /**
     * --------------------------------------------------------------
     * Join chat process
     * params subid hashed
     * --------------------------------------------------------------
     */
    join: function (subid) {
      this.$swal({
        title: "question",
        html: "Are you sure to join this thread ?",
        icon: "question",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.loading();
          this.API.post(this.URL.thread.join, { subthreadid: subid })
            .then(() => {
              this.$swal("Success", "You have joined", "success");
              this.getThreads();
            })
            .catch(({ response }) => {
              this.$swal("Error", response.data.message, "error");
            })
            .finally(() => {
              this.loading(false);
            });
        } else {
          this.$swal("Cancelled", "", "success");
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * check apakah agent sudah join ke chat /belum
     * untuk hidden button join
     * --------------------------------------------------------------
     */
    agentHasJoin: function (arr_agent) {
      let token = localStorage.getItem(appConfig.rwStorage.agent.token);
      if (token !== null) {
        token = this.jwtDecode(token);
        let current = typeof token.agentid !== "undefined" ? token.agentid : 0;

        let agent = [];
        for (let i in arr_agent) {
          agent.push(parseInt(arr_agent[i].id));
        }
        return agent.includes(current);
      }
      return true;
    },
  },
  mounted() {
    this.getThreads();
    this.$store.dispatch("breadcrumb", {
      title: "Chat Page",
      path: "List Chat",
    });
    this.$store.dispatch("activeLink", this.$route.path);
  },
};
</script>

<style scoped></style>
